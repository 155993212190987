@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Mr+Dafoe&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html{
        font-family: 'Raleway', sans-serif;
        overflow-x: hidden;
    }
}

@layer components {
    .slant{
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10vw), 0 100%);
          -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10vw), 0 100%);
          margin-bottom: -10vw;
    }
    .fade-left{
        transform: translateX(-15px);
        transition: opacity .6s,transform .6s;
        opacity: 0;
    }
    .animate-fade{
        transform: translateX(0px);
        opacity: 1;
    }


    .bg-alternatives-secondary-dark-light {
        --tw-bg-opacity: 1;
        background-color: rgb(26 31 80 / var(--tw-bg-opacity));
    }
    .border-alternatives-border {
        --tw-border-opacity: 1;
        border-color: rgb(30 36 87 / var(--tw-border-opacity));
    }
    .bg-alternatives-box-darker {
        --tw-bg-opacity: 1;
        background-color: rgb(4 8 43 / var(--tw-bg-opacity));
    }
    .bg-alternatives-gradient {
        background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(23,28,71,.8)),to(rgba(23,28,71,.4)));
        background-image: linear-gradient(180deg,rgba(23,28,71,.8) 0%,rgba(23,28,71,.4) 100%);
    }
    .bg-brand-blue {
        --tw-bg-opacity: 1;
        background-color: rgb(55 88 249 / var(--tw-bg-opacity));
    }
    .bg-alternatives-secondary-dark-dark {
        --tw-bg-opacity: 1;
        background-color: rgb(23 28 71 / var(--tw-bg-opacity));
    }
    .slot {
        -webkit-transition: all .5s;
        transition: all .5s;
    }
      
}